import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import React from "react";
import { Button, Toolbar, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import makeBackendRequest from "../utils/Backend";

const Header = ({
  authenticated,
  onLogout,
}: {
  authenticated: boolean;
  onLogout: () => void;
}) => {
  const handleLogout = () => {
    makeBackendRequest("POST", "/admin/logout")
      // NOTE: There's nothing can be done if the request fails.
      .catch((error) => {})
      .finally(() => {
        onLogout();
      });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <img
            src={"logo192.png"}
            style={{
              height: 48,
              width: 48,
              marginRight: 16,
            }}
            alt={"logo"}
          />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Loyalty Program Admin Panel
          </Typography>
          {authenticated && (
            <Button onClick={handleLogout} color="inherit" startIcon={<LogoutIcon />}>
              Logout
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
