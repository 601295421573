// makeBackEndRequest behaves differently when the path is AUTH_STATUS_ROUTE
// So, route path is declared here to make sure it's consistent
export const AUTH_STATUS_ROUTE = "/admin/authStatus";

// NOTE: No retries are implemented here.
// Boiler-plated from: mrgold-lapp-user
const makeBackendRequest = (
  method: "POST" | "GET" | "DELETE",
  path: string,
  jBody?: object,
  blob?: BodyInit,
  blobContentType?: string
): Promise<any> =>
  new Promise((resolve, reject) => {
    const headers = new Headers();

    let body: string | BodyInit | undefined;

    if (jBody && blob) {
      reject("Cannot have both jBody and blob");
    }

    // JSON body
    if (jBody) {
      body = JSON.stringify(jBody);
      headers.append("Content-Type", "application/json");
    }

    // Binary body
    if (blob) {
      body = blob;
      headers.append("Content-Type", blobContentType || "application/octet-stream");
    }

    fetch(`${process.env.REACT_APP_BACKEND_URL}${path}`, {
      method,
      headers,
      body,
      credentials: "include",
    })
      .then(async (response) => {
        if (![200, 400, 401, 403, 404, 409].includes(response.status)) {
          throw new Error("Something went wrong...");
        } else {
          // Automatically redirect to login page if the token is invalid
          if (response.status === 401) {
            if (path !== AUTH_STATUS_ROUTE) {
              window.location.href = "/";
            }

            reject("Unauthorized. Please login again.");
            return;
          }

          // Excel data responses are resolved as blobs
          if (
            response.headers.get("Content-Type")?.includes("application/vnd.ms-excel")
          ) {
            resolve({ response, blob: await response.blob() });
            return;
          }

          //// JSON response
          let responseObject: any;

          try {
            responseObject = await response.json();
          } catch (sError) {
            throw new Error("Unexpected Client Error. Please try again.");
          }

          if (responseObject.message) {
            throw new Error(responseObject.message);
          }

          // Success
          resolve(responseObject);
        }
      })
      .catch((error) => {
        alert(error);
        // Failure
        reject(error);
      });
  });

export default makeBackendRequest;
