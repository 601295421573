import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import React, { useEffect, useRef } from "react";
import MyPagination, {
  MyPaginationDefaultLimit,
  MyPaginationRefType,
} from "./MyPagination";
import { getAccountTypeName } from "../utils/Misc";
import makeBackendRequest from "../utils/Backend";

type RedemptionRequest = {
  id: string;
  userId: string;
  productId: string;
  createdAt: string;
  status: number;
  dComment: string;
  user: {
    id: string;
    name: string;
    accountType: number;
  };
  product: {
    id: string;
    name: string;
  };
};

// Redemption Requests Card
const RRCard = () => {
  const [skip, setSkip] = React.useState(0);
  const [limit, setLimit] = React.useState<number>(MyPaginationDefaultLimit);

  const [data, setData] = React.useState<RedemptionRequest[]>([]);
  const [loading, setLoading] = React.useState(false);

  const pendingCheckboxRef = React.useRef<HTMLInputElement>(null);
  const deliveredCheckboxRef = React.useRef<HTMLInputElement>(null);
  const fromTextFieldRef = React.useRef<HTMLInputElement>(null);
  const toTextFieldRef = React.useRef<HTMLInputElement>(null);

  const paginationRef = React.useRef<MyPaginationRefType>();

  const inputFrozen = React.useRef<boolean>(false);

  const hasFirstTimeLoaded = useRef(false);

  const handleInputChange = () => {
    if (inputFrozen.current) {
      return;
    }

    paginationRef.current?.resetPage();
    if (!skip) {
      load();
    }
  };

  // NOTE: This method may be called multiple times.
  // When it's called, it may bug out the UI.
  // No temporary solution yet.
  const load = () => {
    setLoading(true);
    setData([]);

    let delivered: boolean | undefined;

    if (pendingCheckboxRef.current?.checked && !deliveredCheckboxRef.current?.checked) {
      delivered = false;
    }

    if (!pendingCheckboxRef.current?.checked && deliveredCheckboxRef.current?.checked) {
      delivered = true;
    }

    let fromDate: string | undefined;

    if (fromTextFieldRef.current?.value) {
      fromDate = fromTextFieldRef.current?.value + "T00:00:00.000Z";
    }

    let toDate: string | undefined;

    if (toTextFieldRef.current?.value) {
      // Include the whole day
      toDate = toTextFieldRef.current?.value + "T23:59:59.999Z";
    }

    makeBackendRequest("POST", "/admin/redeems", {
      skip,
      limit,
      delivered,
      fromDate,
      toDate,
    })
      .then((res) => {
        setData(res);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        hasFirstTimeLoaded.current = true;
      });
  };

  useEffect(() => {
    if (hasFirstTimeLoaded.current) {
      load();
    }
  }, [skip, limit]);
  const firstTime = useRef(true);

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;

      load();
      return;
    }
  }, []);

  const modifyRequestState = (idx: number, state: number, dComment: string) => {
    const newData = [...data];
    newData[idx].status = state;
    newData[idx].dComment = dComment;
    setData(newData);
  };

  // State based controlled components can be a good alternative to refs at this point.
  // However, it takes a lot of time to implement from scratch. Hence, refs are used.
  const handleResetFilters = () => {
    if (fromTextFieldRef.current) {
      fromTextFieldRef.current.value = "";
    }

    if (toTextFieldRef.current) {
      toTextFieldRef.current.value = "";
    }

    // Prevents the input change handler from triggering
    // This is to prevent multiple requests from being sent
    inputFrozen.current = true;

    if (pendingCheckboxRef.current) {
      if (!pendingCheckboxRef.current.checked) {
        pendingCheckboxRef.current.click();
      }
    }

    if (deliveredCheckboxRef.current) {
      if (!deliveredCheckboxRef.current.checked) {
        deliveredCheckboxRef.current.click();
      }
    }

    // Allow the input change handler to trigger again
    inputFrozen.current = false;

    // Trigger the input change handler
    handleInputChange();
  };

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Typography variant={"h5"} mb={2}>
          Redemption Requests
        </Typography>
        <Typography variant={"h6"} mr={2}>
          Filter
        </Typography>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleInputChange}
                inputRef={pendingCheckboxRef}
                defaultChecked
              />
            }
            label="Pending"
          />
          <FormControlLabel
            style={{}}
            control={
              <Checkbox
                onChange={handleInputChange}
                inputRef={deliveredCheckboxRef}
                defaultChecked
              />
            }
            label="Delivered"
          />
        </FormGroup>
        <Box mt={2} />
        <FormGroup row>
          {/* These date inputs are reliably only on latest versions of chrome */}
          <TextField
            inputRef={fromTextFieldRef}
            onChange={handleInputChange}
            label="From"
            type="date"
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box mr={2} />
          <TextField
            inputRef={toTextFieldRef}
            onChange={handleInputChange}
            label="To"
            type="date"
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Box ml={2} alignSelf={"center"}>
            <Button onClick={handleResetFilters}>Clear Filters</Button>
          </Box>
        </FormGroup>
        <Box mb={1} />
        <TableContainer sx={{ maxHeight: 512 }}>
          <Table stickyHeader aria-label="Redemption Requests">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>RD Name</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align={"center"}>Action/Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell colSpan={6} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {data.map((request, idx) => {
                // Format date to human-readable format
                const date = new Date(request.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });

                const accountType = getAccountTypeName(request.user.accountType);
                const name = request.user.name;
                const productName = request.product.name;

                // This logic is based on the fact that there are only 2 states
                const markAsDelivered = () => {
                  const dComment = prompt(
                    "Enter any final comments before marking this request as delivered...\nEnter '-' if no comments"
                  );

                  // Ignore if user presses cancel
                  if (dComment === null) {
                    return;
                  }

                  if (dComment === "" || dComment.trim() === "") {
                    alert("Error: Comment cannot be empty.");
                    return;
                  }

                  // We do not have to worry about setting the correct dComment as of now
                  // This is because dComment can be set only once during marking as delivered
                  modifyRequestState(idx, -1, "");

                  makeBackendRequest("POST", "/admin/markRedeem", {
                    redeemId: request.id,
                    dComment,
                  })
                    .then((res) => {
                      modifyRequestState(idx, 1, dComment);
                    })
                    .catch((err) => {
                      modifyRequestState(idx, 0, "");
                    });
                };

                return (
                  <TableRow key={request.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell>{date}</TableCell>
                    <TableCell>{accountType}</TableCell>
                    <TableCell
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      {name}
                    </TableCell>
                    <TableCell>{productName}</TableCell>
                    <TableCell>
                      {request.status === 0 ? (
                        <Typography variant={"body2"} color={"error"}>
                          PENDING
                        </Typography>
                      ) : request.status === 1 ? (
                        <Typography variant={"body2"} color={"green"}>
                          DELIVERED
                        </Typography>
                      ) : (
                        <Typography variant={"body2"} color={"orange"}>
                          IN PROGRESS
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align={"center"}>
                      {request.status === 0 ? (
                        <Button onClick={markAsDelivered} variant={"contained"}>
                          Mark As Delivered
                        </Button>
                      ) : request.status === -1 ? (
                        <CircularProgress />
                      ) : request.dComment ? (
                        <Typography variant={"body2"} fontStyle={"italic"}>
                          {request.dComment}
                        </Typography>
                      ) : (
                        "-"
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <MyPagination
          ref={paginationRef}
          onLimitChanged={(newLimit) => setLimit(newLimit)}
          onSkipChanged={(newSkip) => setSkip(newSkip)}
        />
      </Box>
    </Paper>
  );
};

export default RRCard;
