import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button, Container, Grid, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import makeBackendRequest from "../utils/Backend";

const LoginCard = ({ onLogin }: { onLogin: () => void }) => {
  const [processing, setProcessing] = React.useState(false);
  const passwordRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    setProcessing(true);
    const password = passwordRef.current?.value;
    makeBackendRequest("POST", "/admin/login", {
      password,
    })
      .then((response) => {
        onLogin();
      })
      .catch((error) => {
        setProcessing(false);
      });
  };

  return (
    <Container>
      <Grid
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          height: "80vh",
        }}
      >
        <Box mt={2} maxWidth={384} flex={1} marginLeft={"auto"} marginRight={"auto"}>
          <Paper elevation={1}>
            <Box p={2} pb={2.5}>
              <Typography variant={"h4"} mb={4} textAlign={"center"}>
                Login
              </Typography>
              <TextField
                style={{
                  width: "100%",
                }}
                label={"Access Code"}
                type={"password"}
                variant={"filled"}
                disabled={processing}
                inputRef={passwordRef}
              />
              <Box mt={4} display={"flex"} alignItems={"center"} flexDirection={"column"}>
                <Button onClick={handleClick} variant={"contained"} disabled={processing}>
                  {processing ? "Authorizing..." : "Authorize"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Container>
  );
};

export default LoginCard;
