import React, { createRef } from "react";
import { Container, Grid } from "@mui/material";
import RRCard from "./components/RRCard";
import Box from "@mui/material/Box";
import DownloadActionsCard from "./components/DownloadActionsCard";
import UserActionsCard from "./components/UserActionsCard";
import UploadActionsCard from "./components/UploadActionsCard";
import ProductActionsCard from "./components/ProductActionsCard";
import ActionHistoryCard, {
  UploadHistoryCardRefType,
} from "./components/ActionHistoryCard";
import StatsCard, { StatsCardRefType } from "./components/StatsCard";
import SlotActionsCard from "./components/SlotActionsCard";

const Dashboard = () => {
  const uploadHistoryCardRef = createRef<UploadHistoryCardRefType>();
  const statsCardRef = createRef<StatsCardRefType>();

  const reloadAuditHistory = () => {
    uploadHistoryCardRef.current?.reload();
  };

  const handleUploadFinish = () => {
    reloadAuditHistory();
    // Stats card needs to reload only upon uploading users but due to time constraints,
    // it reloads on both
    statsCardRef.current?.reload();
  };

  const handleSlotResetComplete = () => {
    reloadAuditHistory();
  };

  return (
    <Container>
      <Box mt={4} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box>
            <StatsCard ref={statsCardRef} />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box>
            <DownloadActionsCard />
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box>
            <UploadActionsCard onUploadFinished={handleUploadFinish} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <ActionHistoryCard ref={uploadHistoryCardRef} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <RRCard />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <UserActionsCard />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <ProductActionsCard />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <SlotActionsCard onResetComplete={handleSlotResetComplete} />
          </Box>
        </Grid>
      </Grid>
      <Box mt={4} />
    </Container>
  );
};

export default Dashboard;
