import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MyPagination, {
  MyPaginationDefaultLimit,
  MyPaginationRefType,
} from "./MyPagination";
import makeBackendRequest from "../utils/Backend";
import { getUploadHistoryAction, getUploadHistoryTypeName } from "../utils/Misc";
import ReloadButton from "./ReloadButton";

type UploadHistory = {
  id: string;
  createdAt: string;
  type: number;
  status: number;
  description: string;
  variant: number;
};

export type UploadHistoryCardRefType = {
  reload: () => void;
};

const ActionHistoryCard = forwardRef((props, ref) => {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState<number>(MyPaginationDefaultLimit);

  const [data, setData] = useState<UploadHistory[]>([]);
  const [loading, setLoading] = useState(false);

  const paginationRef = useRef<MyPaginationRefType>();

  const hasFirstTimeLoaded = useRef(false);

  // Refresh data
  const handleReload = () => {
    paginationRef.current?.resetPage();

    if (!skip) {
      load();
    }
  };

  useImperativeHandle(ref, () => ({
    // Trigger data reload from parent
    reload: handleReload,
  }));

  const load = () => {
    setLoading(true);
    setData([]);

    makeBackendRequest("POST", "/admin/uploadHistory", {
      skip,
      limit,
    })
      .then(setData)
      .catch(console.error)
      .finally(() => {
        setLoading(false);
        hasFirstTimeLoaded.current = true;
      });
  };

  useEffect(() => {
    if (hasFirstTimeLoaded.current) {
      load();
    }
  }, [skip, limit]);

  const firstTime = useRef(true);

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;

      load();
      return;
    }
  }, []);

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={"h5"} mb={2}>
            Action History
          </Typography>
          {!loading && <ReloadButton onClick={handleReload} />}
        </Box>
        <TableContainer sx={{ maxHeight: 512 }}>
          <Table stickyHeader aria-label="Upload History">
            <TableHead>
              <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align={"center"}>Brief</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell colSpan={6} align={"center"}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {data.map((history, idx) => {
                const date = new Date(history.createdAt).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });

                return (
                  <TableRow hover key={history.id}>
                    <TableCell>{getUploadHistoryAction(history.variant)}</TableCell>
                    <TableCell>{date}</TableCell>
                    <TableCell>
                      <Typography variant={"body2"}>
                        {getUploadHistoryTypeName(history.type)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant={"body2"}
                        color={
                          history.status === 0
                            ? "orange"
                            : history.status === 1
                            ? "green"
                            : history.status === 2
                            ? "error"
                            : "gray"
                        }
                      >
                        {history.status === 0
                          ? "IN PROGRESS"
                          : history.status === 1
                          ? "SUCCESS"
                          : history.status === 2
                          ? "FAILED"
                          : "UNKNOWN"}
                      </Typography>
                    </TableCell>
                    <TableCell align={"center"}>{history.description}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <MyPagination
          ref={paginationRef}
          onLimitChanged={(newLimit) => setLimit(newLimit)}
          onSkipChanged={(newSkip) => setSkip(newSkip)}
        />
      </Box>
    </Paper>
  );
});

export default ActionHistoryCard;
