import { IconButton, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";

const ReloadButton = ({ onClick }: { onClick: () => void }) => (
  <Tooltip title={"Reload"}>
    <IconButton color={"secondary"} size={"medium"} onClick={onClick}>
      <RefreshIcon color={"inherit"} fontSize={"inherit"} />
    </IconButton>
  </Tooltip>
);

export default ReloadButton;
