import React from "react";
import Header from "./components/Header";
import LoginCard from "./components/LoginCard";
import makeBackendRequest, { AUTH_STATUS_ROUTE } from "./utils/Backend";
import Dashboard from "./Dashboard";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

const Loader = () => {
  return (
    <Box mt={4} display={"flex"} alignItems={"center"} flexDirection={"column"}>
      <CircularProgress />
    </Box>
  );
};

// TODO: Fix incorrect date.setHours call
function App() {
  /*
   * The state of the application.
   * PENDING: The application is validating the user's session.
   * YES: The user is logged in.
   * NO: The user is not logged in.
   */
  const [loginStatus, setLoginStatus] = React.useState<"pending" | "yes" | "no">(
    "pending"
  );

  const isLoaded = React.useRef(false);

  const validateAuthStatus = () => {
    makeBackendRequest("POST", AUTH_STATUS_ROUTE)
      .then((response) => {
        setLoginStatus("yes");
      })
      .catch((error) => {
        setLoginStatus("no");
      });
  };

  React.useEffect(() => {
    if (isLoaded.current) {
      return;
    }
    isLoaded.current = true;

    validateAuthStatus();
  }, []);

  return (
    <>
      <Header
        authenticated={loginStatus === "yes"}
        onLogout={() => setLoginStatus("no")}
      />
      <main>
        {loginStatus === "pending" && <Loader />}
        {loginStatus === "no" && <LoginCard onLogin={() => setLoginStatus("yes")} />}
        {loginStatus === "yes" && <Dashboard />}
      </main>
    </>
  );
}

export default App;
