import { CircularProgress, Typography } from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import makeBackendRequest from "../utils/Backend";
import ReloadButton from "./ReloadButton";

export type StatsCardRefType = {
  reload: () => void;
};

const StatsCard = forwardRef((props, ref) => {
  const [stats, setStats] = useState<
    | {
        normalUsers: number;
        slotUsers: number;
      }
    | undefined
  >(undefined);

  const [loading, setLoading] = useState(false);

  const firstLoad = useRef<boolean>(true);

  const load = () => {
    setLoading(true);
    makeBackendRequest("GET", "/admin/stats")
      .then(setStats)
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;

      load();
      return;
    }
  }, []);

  useImperativeHandle(ref, () => ({
    // Trigger data reload from parent
    reload: load,
  }));

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={"h5"} mb={2}>
            Stats
          </Typography>
          {!loading && <ReloadButton onClick={load} />}
        </Box>
        {stats === undefined || loading ? (
          <CircularProgress size={24} />
        ) : (
          <Box display="flex">
            <Metric name={"Normal Users"} value={stats.normalUsers} />
            <Box mx={4} />
            <Metric name={"Slot Users"} value={stats.slotUsers} />
          </Box>
        )}
      </Box>
    </Paper>
  );
});

const Metric = ({ name, value }: { name: string; value?: string | number }) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="h6">{name}</Typography>
    <Typography variant="h4">{value}</Typography>
  </Box>
);

export default StatsCard;
