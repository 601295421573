export const getAccountTypeName = (accountType: number): string => {
  switch (accountType) {
    case 0:
      return "Distributor";
    case 1:
      return "Retailer";
    case 2:
      return "Royal Retailer";
    // Slot Users
    case 101:
      return "Slot 1";
    case 102:
      return "Slot 2";
    case 103:
      return "Slot 3";
    case 104:
      return "Slot 4";
    case 105:
      return "Slot 5";
    default:
      return "Unknown";
  }
};

export const getUploadHistoryTypeName = (uploadHistoryType: number): string => {
  switch (uploadHistoryType) {
    case 0:
      return "DISTRIBUTORS";
    case 1:
      return "RETAILERS";
    case 2:
      return "ROYAL RETAILERS";
    // Slot Users
    case 101:
      return "SLOT 1";
    case 102:
      return "SLOT 2";
    case 103:
      return "SLOT 3";
    case 104:
      return "SLOT 4";
    case 105:
      return "SLOT 5";
    case 100:
      return "POINTS";
    default:
      return "UNKNOWN";
  }
};

export const getUploadHistoryAction = (variant: number): string => {
  switch (variant) {
    case 0:
      return "UPLOAD";
    case 100:
      return "RESET";
    default:
      return "UNKNOWN";
  }
};
