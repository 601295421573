import React, { useImperativeHandle } from "react";
import { TablePagination } from "@mui/material";

export const MyPaginationDefaultLimit = 15;

export type MyPaginationRefType = {
  resetPage: () => void;
};

const MyPagination = React.forwardRef(
  (
    {
      onSkipChanged,
      onLimitChanged,
      defaultSkip,
      defaultLimit,
    }: {
      onSkipChanged: (newSkip: number) => void;
      onLimitChanged: (newLimit: number) => void;
      defaultLimit?: number;
      defaultSkip?: number;
    },
    ref
  ) => {
    const [limit, setLimit] = React.useState<number>(
      defaultLimit !== undefined ? defaultLimit : MyPaginationDefaultLimit
    );

    const [page, setPage] = React.useState(
      defaultSkip !== undefined ? Math.floor(defaultSkip / limit) : 0
    );

    useImperativeHandle(ref, () => ({
      resetPage: () => {
        onSkipChanged(0);
        setPage(0);
      },
    }));

    return (
      <TablePagination
        rowsPerPageOptions={[15, 25, 50]}
        component="div"
        count={-1}
        rowsPerPage={limit}
        page={page}
        labelDisplayedRows={({ from, to, count }) => {
          return `Page ${page + 1}`;
        }}
        onPageChange={(e, newPage) => {
          setPage(newPage);
          const skip = newPage * limit;
          onSkipChanged(skip);
        }}
        onRowsPerPageChange={(e) => {
          const newLimit = Number(e.target.value);
          setPage(0);
          setLimit(newLimit);
          onSkipChanged(0);
          onLimitChanged(newLimit);
        }}
      />
    );
  }
);

export default MyPagination;
