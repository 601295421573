import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  CircularProgress,
  Fab,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { getAccountTypeName } from "../utils/Misc";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import MyPagination, {
  MyPaginationDefaultLimit,
  MyPaginationRefType,
} from "./MyPagination";
import makeBackendRequest from "../utils/Backend";

const defaultNewProduct = {
  name: "-",
  description: "-",
  cost: 100,
  imageUrl: "",
  category: 0,
  isAvailable: true,
};

const ProductActionsCard = () => {
  const [mode, setMode] = React.useState<"search" | "info" | "add" | "edit">("search");
  const [skip, setSkip] = React.useState(0);
  const [limit, setLimit] = React.useState<number>(MyPaginationDefaultLimit);

  const [data, setData] = React.useState<Product[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [selectedProduct, setSelectedProduct] = React.useState<Product | undefined>(
    undefined
  );

  // Add/Edit
  const [saving, setSaving] = React.useState(false);

  const formSectionRef = React.createRef<{
    getModifiedFields: () => { [key: string]: any };
    resetModifiedFields: () => void;
  }>();

  React.useEffect(() => {
    formSectionRef.current?.resetModifiedFields();
  }, [selectedProduct]);

  // Save & Submit button click handling
  const handleSave = () => {
    if (!formSectionRef.current) return;

    const modifiedFields = formSectionRef.current.getModifiedFields();
    if (Object.keys(modifiedFields).length === 0) {
      alert("Error: No changes to save. Modify something to save.");
      return;
    }

    // " " is used to indicate that the image has to be reset to default
    // This is a bad implementation but there's no time for change
    if (modifiedFields["image"] === " ") {
      if (mode === "add") {
        // Deleting the key will make sure that the default image is used
        delete modifiedFields["image"];
      }
      // In case of "edit", the backend will automatically use the default image
      // when the image field is set to " "
    }

    setSaving(true);

    if (mode === "edit") {
      makeBackendRequest("POST", "/admin/editProduct", {
        productId: selectedProduct?.id,
        ...modifiedFields,
      })
        .then((res) => {
          if (selectedProduct) {
            const newSelectedProduct = { ...selectedProduct };
            Object.keys(modifiedFields).forEach((key) => {
              const newValue = modifiedFields[key];

              // Set migrated key for image
              if (key === "image") {
                key = "imageUrl";
              }

              newSelectedProduct[key] = newValue;
              // Pointer of original object is changed to make sure that
              // the table UI is updated with new values upon going back.
              selectedProduct[key] = newValue;
            });
            console.log(newSelectedProduct);
            setSelectedProduct(newSelectedProduct);
            setMode("info");
          }
        })
        .catch((err) => {})
        .finally(() => {
          setSaving(false);
        });
    } else if (mode === "add") {
      makeBackendRequest("POST", "/admin/addProduct", {
        ...modifiedFields,
      })
        .then((res) => {
          alert("Saved!");
          setMode("info");
          setSelectedProduct(res);
          paginationRef.current?.resetPage();
          load();
        })
        .catch((err) => {})
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const paginationRef = React.useRef<MyPaginationRefType>();

  const [checkBoxState, setCheckBoxState] = React.useState<{
    active: boolean;
    inactive: boolean;
    category: number;
  }>({
    active: true,
    inactive: true,
    category: 999,
  });

  const handleCheckboxChange = (name: "active" | "inactive", checked: boolean) => {
    setCheckBoxState({ ...checkBoxState, [name]: checked });
    paginationRef.current?.resetPage();
  };

  const handleCategoryChange = (value: number) => {
    setCheckBoxState({ ...checkBoxState, category: value });
    paginationRef.current?.resetPage();
  };

  const hasFirstTimeLoaded = useRef(false);

  const load = () => {
    setLoading(true);
    setData([]);

    let category: number | undefined =
      checkBoxState.category === 999 ? undefined : checkBoxState.category;

    let active: boolean | undefined;

    if (checkBoxState.active && !checkBoxState.inactive) {
      active = true;
    }

    if (!checkBoxState.active && checkBoxState.inactive) {
      active = false;
    }

    makeBackendRequest("POST", "/admin/products", {
      skip,
      limit,
      category,
      isAvailable: active,
    })
      .then((res) => {
        setData(res);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        hasFirstTimeLoaded.current = true;
      });
  };

  useEffect(() => {
    if (hasFirstTimeLoaded.current) {
      load();
    }
  }, [skip, limit, checkBoxState]);

  const firstTime = useRef(true);

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;

      load();
      return;
    }
  }, []);

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={"h5"} mb={2}>
            Products
          </Typography>
          {mode === "search" && (
            <Tooltip title={"Create Product"}>
              <Fab onClick={() => setMode("add")} size={"small"} color={"secondary"}>
                <AddIcon />
              </Fab>
            </Tooltip>
          )}
        </Box>

        {mode === "info" || mode === "edit" || mode === "add" ? (
          <>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <IconButton
                onClick={() => {
                  setMode("search");
                  setSelectedProduct(undefined);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant={"h6"} mr={2}>
                {mode === "info"
                  ? "Product Info"
                  : mode === "edit"
                  ? "Edit Product"
                  : "Create Product"}
              </Typography>
              {mode === "edit" && (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flex={1}
                  alignItems={"flex-end"}
                >
                  <Button
                    disabled={saving}
                    startIcon={<SaveIcon />}
                    variant={"contained"}
                    onClick={handleSave}
                  >
                    {saving ? "Saving..." : "Save"}
                  </Button>
                </Box>
              )}
              {mode === "add" && (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flex={1}
                  alignItems={"flex-end"}
                  onClick={handleSave}
                >
                  <Button disabled={saving} variant={"contained"}>
                    {saving ? "Submitting..." : "Submit"}
                  </Button>
                </Box>
              )}
            </Box>
            <Box mt={2} />
            <Box maxHeight={"75vh"} overflow={"scroll"} paddingX={5}>
              {mode === "info" && selectedProduct && (
                <InfoSection product={selectedProduct} />
              )}
              {mode === "edit" && selectedProduct && (
                <FormSectionUI ref={formSectionRef} product={selectedProduct} />
              )}
              {mode === "add" && (
                <FormSectionUI ref={formSectionRef} product={defaultNewProduct} />
              )}
            </Box>
          </>
        ) : undefined}

        {mode === "search" && (
          <>
            <Typography variant={"h6"} mr={2}>
              Filter
            </Typography>
            <FormGroup
              style={{
                flexDirection: "row",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleCheckboxChange("active", e.target.checked)}
                    checked={checkBoxState.active}
                  />
                }
                label="Active"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleCheckboxChange("inactive", e.target.checked)}
                    checked={checkBoxState.inactive}
                  />
                }
                label="Inactive"
              />
            </FormGroup>
            <Box mt={2} />
            <FormGroup>
              <FormControl sx={{ width: 192 }}>
                <InputLabel id="product-category-select-label">Category</InputLabel>
                <Select
                  labelId="product-category-select-label"
                  id="product-category-select"
                  value={checkBoxState.category}
                  label="Category"
                  onChange={(e) => handleCategoryChange(Number(e.target.value))}
                >
                  <MenuItem value={999}>All</MenuItem>
                  <MenuItem value={0}>Distributors</MenuItem>
                  <MenuItem value={1}>Retailers</MenuItem>
                  <MenuItem value={2}>Royal Retailers</MenuItem>
                  <MenuItem value={101}>Slot 1</MenuItem>
                  <MenuItem value={102}>Slot 2</MenuItem>
                  <MenuItem value={103}>Slot 3</MenuItem>
                  <MenuItem value={104}>Slot 4</MenuItem>
                  <MenuItem value={105}>Slot 5</MenuItem>
                </Select>
              </FormControl>
            </FormGroup>
            <TableContainer sx={{ maxHeight: 512 }}>
              <Table stickyHeader aria-label="Products">
                <TableHead>
                  <TableRow>
                    <TableCell align={"center"}>Action</TableCell>
                    <TableCell align={"center"}>Available For</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={6} align={"center"}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map((product, idx) => {
                    return (
                      <TableRow key={product.id} hover role="checkbox" tabIndex={-1}>
                        <TableCell align={"center"}>
                          <Tooltip title="Edit Product">
                            <IconButton
                              onClick={() => {
                                setSelectedProduct(product);
                                setMode("edit");
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"View Details"}>
                            <IconButton
                              onClick={() => {
                                setSelectedProduct(product);
                                setMode("info");
                              }}
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell align={"center"}>
                          {getAccountTypeName(product.category)}
                        </TableCell>
                        <TableCell>{product.name}</TableCell>
                        <TableCell>{product.cost}</TableCell>
                        <TableCell>
                          {product.isAvailable ? (
                            <Typography variant={"body2"} color={"green"}>
                              ACTIVE
                            </Typography>
                          ) : (
                            <Typography variant={"body2"} color={"error"}>
                              INACTIVE
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <MyPagination
              ref={paginationRef}
              defaultSkip={skip}
              defaultLimit={limit}
              onLimitChanged={(newLimit) => setLimit(newLimit)}
              onSkipChanged={(newSkip) => setSkip(newSkip)}
            />
          </>
        )}
      </Box>
    </Paper>
  );
};

type Product = {
  id?: string;
  name: string;
  description: string;
  cost: number;
  category: number;
  isAvailable: boolean;
  imageUrl: string;
  [key: string]: string | number | boolean | undefined;
};

const ProductImage = ({ src }: { src: string }) => {
  const [bg, setBg] = useState<string | undefined>("#e0e0e0");

  return (
    <Box
      component={"img"}
      sx={{
        backgroundColor: bg,
        minWidth: 512,
        minHeight: 256,
      }}
      src={src}
      onLoad={() => setBg(undefined)}
      alt={"Product Image"}
    />
  );
};

// Info Section
const InfoSection = ({ product }: { product: Product }) => {
  const [processing, setProcessing] = React.useState(false);

  const updateActivationStatus = async (status: boolean) => {
    setProcessing(true);

    makeBackendRequest("POST", "/admin/editProduct", {
      productId: product.id,
      available: status,
    })
      .then((res) => {
        if (status) {
          alert("Product activated successfully");
        } else {
          alert("Product deactivated successfully");
        }

        // Not the right approach, but works for now
        product.isAvailable = status;
      })
      .catch((err) => {})
      .finally(() => {
        setProcessing(false);
      });
  };

  const activateProduct = async () => updateActivationStatus(true);

  const deactivateProduct = async () => updateActivationStatus(false);

  const Item = ({
    label,
    value,
    valueProps,
  }: {
    label: string;
    value: string;
    valueProps?: TypographyProps;
  }) => (
    <Box mt={2}>
      <Typography variant={"subtitle2"}>{label}</Typography>
      <Typography variant={"body2"} whiteSpace={"pre-line"} {...valueProps}>
        {value}
      </Typography>
    </Box>
  );

  return (
    <Box>
      {processing && (
        <Box>
          <CircularProgress />
        </Box>
      )}
      {!processing && (
        <>
          {product.isAvailable ? (
            <Button onClick={deactivateProduct} variant={"contained"} color={"error"}>
              Mark Inactive
            </Button>
          ) : (
            <Button onClick={activateProduct} variant={"contained"} color={"primary"}>
              Mark Active
            </Button>
          )}
        </>
      )}
      <Box
        mt={4}
        mb={2}
        maxWidth={512}
        marginLeft={"auto"}
        marginRight={"auto"}
        border={"1px solid #e0e0e0"}
        p={2}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box alignSelf={"center"}>
          {product.id && <ProductImage src={product.imageUrl} />}
        </Box>
        <Item label={"Name"} value={product.name} />
        <Item label={"Status"} value={product.isAvailable ? "Active" : "Inactive"} />
        <Item label={"Available For"} value={getAccountTypeName(product.category)} />
        <Item label={"Price"} value={product.cost.toString()} />
        <Item label={"Description"} value={product.description} />
      </Box>
    </Box>
  );
};

// Add/Edit Section
const FormSectionUI = React.forwardRef(({ product }: { product: Product }, ref) => {
  const isAddSection = product.id === undefined;

  const modifiedFields = React.useRef<{
    name?: string;
    description?: string;
    cost?: number;
    category?: number;
    image?: string;
  }>(
    isAddSection
      ? {
          name: product.name,
          description: product.description,
          cost: product.cost,
          category: product.category,
        }
      : {}
  );

  useImperativeHandle(ref, () => ({
    getModifiedFields: () => modifiedFields.current,
    resetModifiedFields: () => {
      modifiedFields.current = {};
    },
  }));

  const [previewImage, setPreviewImage] = React.useState<string | undefined>(undefined);

  const resetCurrentPreviewImage = () => {
    modifiedFields.current.image = " ";
    setPreviewImage(" ");
  };

  const setNewPreviewImage = (imageB64: string) => {
    modifiedFields.current.image = imageB64;
    setPreviewImage(imageB64);
  };

  const handleSelectImageClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
    resetCurrentPreviewImage();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      if (file.type !== "image/png") {
        alert("Error: Only png files are supported");
        return;
      }

      if (file.size > 1.5 * 1024 * 1024) {
        alert("Error: File size too large. Max size is 1.5MB");
        return;
      }

      // Convert to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const imageB64 = reader.result as string;

        const tempImage = new Image();
        tempImage.onload = () => {
          if (tempImage.width !== 512 || tempImage.height !== 512) {
            alert("Error: Image dimensions must be 512x512");
            return;
          }

          setNewPreviewImage(imageB64);
        };
        tempImage.src = imageB64;
      };

      reader.onerror = (error) => {
        alert(
          "Error: Unknown error occurred while processing image. Please try again. If this is repeated, please use a different image or web browser."
        );
      };
    }
  };

  return (
    <Box
      my={2}
      maxWidth={512}
      marginLeft={"auto"}
      marginRight={"auto"}
      border={"1px solid #e0e0e0"}
      p={2}
      display={"flex"}
      flexDirection={"column"}
      sx={{
        textAlign: "center",
      }}
      alignItems={"center"}
    >
      {product.id && !previewImage ? (
        <ProductImage src={product.imageUrl} />
      ) : previewImage && previewImage.trim() ? (
        <ProductImage src={previewImage} />
      ) : (
        <></>
      )}
      <Button component="label">
        {"Select Image"}
        <input
          onClick={handleSelectImageClick}
          onChange={handleFileChange}
          hidden
          type={"file"}
          accept={".png"}
        />
      </Button>

      <Box mt={1} width={"100%"}>
        <Alert severity="info" sx={{ textAlign: "left" }}>
          <AlertTitle>Image Specification</AlertTitle>
          <strong>Resolution</strong> - 512x512
          <br />
          <strong>File Type</strong> - PNG
          <br />
          <strong>Max Size</strong> - 1.5MB (Smaller size is preferred)
        </Alert>
      </Box>

      {isAddSection && (
        <Box mt={4} width={"100%"}>
          <FormControl fullWidth>
            <InputLabel id="af-select-label">Available For</InputLabel>
            <Select
              labelId="af-select-label"
              defaultValue={product.category}
              label={"Available For"}
              onChange={(e) => (modifiedFields.current.category = Number(e.target.value))}
            >
              <MenuItem value={0}>Distributors</MenuItem>
              <MenuItem value={1}>Retailers</MenuItem>
              <MenuItem value={2}>Royal Retailers</MenuItem>
              <MenuItem value={101}>Slot 1</MenuItem>
              <MenuItem value={102}>Slot 2</MenuItem>
              <MenuItem value={103}>Slot 3</MenuItem>
              <MenuItem value={104}>Slot 4</MenuItem>
              <MenuItem value={105}>Slot 5</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      <Box mt={4} width={"100%"}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label={"Price"}
          defaultValue={product.cost}
          type={"number"}
          variant="outlined"
          fullWidth={true}
          onChange={(e) => (modifiedFields.current.cost = Number(e.target.value))}
        />
      </Box>

      <Box mt={4} width={"100%"}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label={"Name"}
          defaultValue={product.name}
          variant="outlined"
          fullWidth={true}
          onChange={(e) => (modifiedFields.current.name = e.target.value)}
        />
      </Box>

      <Box mt={4} width={"100%"}>
        <TextField
          InputLabelProps={{ shrink: true }}
          label={"Description"}
          defaultValue={product.description}
          variant="outlined"
          fullWidth={true}
          rows={8}
          multiline={true}
          onChange={(e) => (modifiedFields.current.description = e.target.value)}
        />
      </Box>
    </Box>
  );
});

export default ProductActionsCard;
