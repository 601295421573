import React from "react";
import {
  Button,
  FormGroup,
  FormLabel,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import DownloadIcon from "@mui/icons-material/Download";
import makeBackendRequest from "../utils/Backend";

// Download Actions Card
const DownloadActionsCard = () => {
  const [mode, setMode] = React.useState<"menu" | "download-transactions">("menu");

  const downloadPointsData = () => {
    window.open(process.env.REACT_APP_BACKEND_URL + "/admin/downloadPointsDump");
  };

  const downloadTemplates = () => {
    window.open(process.env.REACT_APP_BACKEND_URL + "/admin/downloadTemplates");
  };

  const [processing, setProcessing] = React.useState(false);

  const fromTextFieldRef = React.useRef<HTMLInputElement>(null);
  const toTextFieldRef = React.useRef<HTMLInputElement>(null);

  const handleDownloadTransactions = () => {
    const from = fromTextFieldRef.current?.value;
    const to = toTextFieldRef.current?.value;

    if (!from || !to) {
      alert("Please enter both dates");
      return;
    }

    const startDate: string = from + "T00:00:00.000Z";
    let endDate: string = to + "T23:59:59.999Z";

    if (startDate > endDate) {
      alert("FROM date cannot be greater than TO date");
      return;
    }

    setProcessing(true);

    // All good, download the file
    makeBackendRequest("POST", "/admin/downloadTxns", {
      startDate,
      endDate,
    })
      .then(({ response, blob }) => {
        const filename = response.headers
          .get("Content-Disposition")
          ?.split("=")[1]
          .replace(/"/g, "");

        // Experimental code tested only on latest Chrome
        const fileURL = window.URL.createObjectURL(blob);

        // Download the file
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = filename;
        a.click();
        a.remove();
        // Delete the file from memory
        window.URL.revokeObjectURL(fileURL);

        setMode("menu");
      })
      .catch((error) => {})
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Typography variant={"h5"} mb={2}>
          Download Actions
        </Typography>
        {mode === "menu" && (
          <FormGroup>
            <Box display={"flex"} flex={1} flexDirection={"column"}>
              <Button
                onClick={downloadPointsData}
                variant="contained"
                startIcon={<DownloadIcon />}
              >
                Download Points Data
              </Button>
              <Box mt={2} />
              <Button
                onClick={() => setMode("download-transactions")}
                variant="contained"
                startIcon={<DownloadIcon />}
              >
                Download User Transactions
              </Button>
              <Box mt={2} />
              <Button
                onClick={downloadTemplates}
                variant={"outlined"}
                startIcon={<DownloadIcon />}
              >
                Download Bulk Upload (.XLSX) templates
              </Button>
            </Box>
          </FormGroup>
        )}
        {mode === "download-transactions" && (
          <>
            {processing && (
              <Box>
                <LinearProgress />
                <Typography variant={"subtitle1"} mt={2}>
                  Generating... Please wait.
                </Typography>
              </Box>
            )}
            {!processing && (
              <FormGroup>
                <Box display={"flex"} flex={1} flexDirection={"column"}>
                  <Typography variant={"h6"} mb={2}>
                    Download Transactions
                  </Typography>
                  <FormLabel>Date</FormLabel>
                  <Box mt={2} />
                  <FormGroup row>
                    <TextField
                      inputRef={fromTextFieldRef}
                      label="From"
                      type="date"
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Box mr={2} />
                    <TextField
                      inputRef={toTextFieldRef}
                      label="To"
                      type="date"
                      sx={{ width: 220 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormGroup>

                  <Box mt={4} />
                  <Button onClick={handleDownloadTransactions} variant={"contained"}>
                    Download
                  </Button>
                  <Button onClick={() => setMode("menu")} color={"error"}>
                    Cancel
                  </Button>
                </Box>
              </FormGroup>
            )}
          </>
        )}
      </Box>
    </Paper>
  );
};

export default DownloadActionsCard;
