import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import makeBackendRequest from "../utils/Backend";

const AccountTypes = [
  { value: 0, label: "Distributor" },
  { value: 1, label: "Retailer" },
  { value: 2, label: "Royal Retailer" },
  // Slot users
  { value: 101, label: "Slot 1" },
  { value: 102, label: "Slot 2" },
  { value: 103, label: "Slot 3" },
  { value: 104, label: "Slot 4" },
  { value: 105, label: "Slot 5" },
];

// Not the best way to do this, but it works for now
const UploadActionsCard = ({ onUploadFinished }: { onUploadFinished: () => void }) => {
  const [mode, setMode] = React.useState<
    "menu" | "upload-users" | "upload-points" | "deduct-points"
  >("menu");

  const [processing, setProcessing] = React.useState(false);

  const accountType = React.useRef("0");
  const inputFileRef = React.useRef<HTMLInputElement>(null);

  const uploadUsers = (fileBlob: Blob) => {
    return makeBackendRequest(
      "POST",
      `/admin/addNewUsers?type=${accountType.current}`,
      undefined,
      fileBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
      .then((response) => {
        setMode("menu");
        alert("Users uploaded successfully");
      })
      .catch((error) => {})
      .finally(() => {
        onUploadFinished();
      });
  };

  const uploadPoints = (fileBlob: Blob) => {
    return makeBackendRequest(
      "POST",
      "/admin/addPoints",
      undefined,
      fileBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
      .then((response) => {
        setMode("menu");
        alert("Points uploaded successfully");
      })
      .catch((error) => {})
      .finally(() => {
        onUploadFinished();
      });
  };

  const deductPoints = (fileBlob: Blob) => {
    return makeBackendRequest(
      "POST",
      "/admin/deductPoints",
      undefined,
      fileBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
      .then((response) => {
        setMode("menu");
        alert("Points deducted successfully");
      })
      .catch((error) => {})
      .finally(() => {
        onUploadFinished();
      });
  };

  const handleUpload = async () => {
    setProcessing(true);

    try {
      if (
        inputFileRef.current &&
        inputFileRef.current.files &&
        inputFileRef.current.files[0]
      ) {
        const file = inputFileRef.current.files[0];
        if (
          file.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          throw new Error("Invalid file type. File must be an Excel (.XLSX) file.");
        }

        const fileBlob = new Blob([file], { type: file.type });

        if (mode === "upload-users") {
          await uploadUsers(fileBlob);
        } else if (mode === "upload-points") {
          await uploadPoints(fileBlob);
        } else if (mode === "deduct-points") {
          // eslint-disable-next-line no-restricted-globals
          if (confirm("Are you sure you want to deduct points?")) {
            await deductPoints(fileBlob);
          }
        } else {
          // The control should never reach here.
          // If it does, it means that there is a problem with implementation.
          throw new Error("Invalid mode");
        }
        setProcessing(false);
      } else {
        throw new Error("No file selected");
      }
    } catch (e: any) {
      setProcessing(false);
      alert(e.message);
    }
  };

  return (
    <Paper variant="outlined">
      <Box p={2} minHeight={189.52}>
        <Typography variant={"h5"} mb={2}>
          Bulk Upload Actions
        </Typography>
        <FormGroup>
          {mode === "menu" && (
            <Box display={"flex"} flex={1} flexDirection={"column"}>
              <Button
                onClick={() => setMode("upload-users")}
                startIcon={<UploadFileIcon />}
                variant={"contained"}
              >
                Upload New Users (.xlsx file)
              </Button>
              <Box mt={2} />
              <Button
                onClick={() => setMode("upload-points")}
                startIcon={<UploadFileIcon />}
                variant={"contained"}
              >
                Upload Points (.xlsx file)
              </Button>
              <Box mt={2} />
              <Button
                onClick={() => setMode("deduct-points")}
                startIcon={<UploadFileIcon />}
                variant={"outlined"}
                color={"error"}
              >
                Deduct Points (.xlsx file)
              </Button>
            </Box>
          )}
          {["upload-users", "upload-points", "deduct-points"].includes(mode) ? (
            <Box display={"flex"} flex={1} flexDirection={"column"}>
              <Typography variant={"h6"} mb={2}>
                {mode === "upload-users"
                  ? "Upload New Users"
                  : mode === "upload-points"
                  ? "Upload Points Transactions"
                  : "Deduct Points Transactions"}
              </Typography>
              {!processing && (
                <FormControl>
                  {mode === "upload-users" && (
                    <>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Account Type
                      </FormLabel>
                      <RadioGroup
                        defaultValue={accountType.current}
                        onChange={(e) => (accountType.current = e.target.value)}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                      >
                        {AccountTypes.map((accountType) => (
                          <FormControlLabel
                            // Value must be a string
                            value={`${accountType.value}`}
                            control={<Radio />}
                            label={accountType.label}
                          />
                        ))}
                      </RadioGroup>
                      <Box mt={2} />
                    </>
                  )}

                  <input
                    ref={inputFileRef}
                    type={"file"}
                    accept={".xlsx"}
                    style={{
                      fontSize: 16,
                    }}
                  />
                  <Box mt={4} />
                  <Button
                    onClick={handleUpload}
                    variant={"contained"}
                    color={mode === "deduct-points" ? "error" : "primary"}
                  >
                    Upload
                  </Button>
                  <Button
                    onClick={() => setMode("menu")}
                    color={mode === "deduct-points" ? "primary" : "error"}
                  >
                    Cancel
                  </Button>
                </FormControl>
              )}
              {processing && (
                <Box>
                  <LinearProgress />
                  <Typography variant={"subtitle1"} mt={2}>
                    Processing... Please wait.
                  </Typography>
                </Box>
              )}
            </Box>
          ) : undefined}
        </FormGroup>
      </Box>
    </Paper>
  );
};

export default UploadActionsCard;
