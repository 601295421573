import Box from "@mui/material/Box";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import makeBackendRequest from "../utils/Backend";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import MyPagination, {
  MyPaginationDefaultLimit,
  MyPaginationRefType,
} from "./MyPagination";
import ReloadButton from "./ReloadButton";

type Slot = {
  id: number;
  name: string;
  redemptionAllowed: boolean;
  cardTitle: string;
  cardDescription: string;
  cardImageUrl: string;
  totalRedeemedUsers: number;
};

const SlotActionsCard = ({ onResetComplete }: { onResetComplete: () => void }) => {
  const [mode, setMode] = React.useState<"list" | "info" | "edit">("list");
  const [slots, setSlots] = useState<Slot[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<Slot | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const firstLoad = useRef<boolean>(true);

  const fetchSlots = () => {
    setLoading(true);
    makeBackendRequest("POST", "/admin/slots")
      .then(setSlots)
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;

      fetchSlots();
      return;
    }
  }, []);

  const onUserDelete = () => {
    if (slots && selectedSlot) {
      if (slots[selectedSlot.id - 1].totalRedeemedUsers === 0) {
        return;
      }

      slots[selectedSlot.id - 1].totalRedeemedUsers -= 1;
      setSlots([...slots]);
    }
  };

  const onListReset = () => {
    if (slots && selectedSlot) {
      slots[selectedSlot.id - 1].totalRedeemedUsers = 0;
      setSlots([...slots]);
    }
  };

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant={"h5"} mb={2}>
            Slots
          </Typography>
          {!loading && mode === "list" && <ReloadButton onClick={fetchSlots} />}
        </Box>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          {
            list: (
              <List
                onInfoPressed={(slot) => {
                  setSelectedSlot(slot);
                  setMode("info");
                }}
                onEditPressed={(slot) => {
                  setSelectedSlot(slot);
                  setMode("edit");
                }}
                slots={slots}
              />
            ),
            // Since we're using typescript, the following will never happen.
            info: selectedSlot ? (
              <Info
                onDelete={onUserDelete}
                onReset={onListReset}
                onResetComplete={onResetComplete}
                onBackPressed={() => setMode("list")}
                slot={selectedSlot}
              />
            ) : (
              <></>
            ),
            edit: selectedSlot ? (
              <Edit
                onBackPressed={() => setMode("list")}
                onSlotUpdated={(updatedSlot) => {
                  // Reflect the updated changes to state and show the updated info sub page
                  setSlots(
                    slots.map((slot) => (slot.id === updatedSlot.id ? updatedSlot : slot))
                  );
                  setSelectedSlot(updatedSlot);
                  setMode("info");
                }}
                slot={selectedSlot}
              />
            ) : (
              <></>
            ),
          }[mode]
        )}
      </Box>
    </Paper>
  );
};

const List = ({
  slots,
  onInfoPressed,
  onEditPressed,
}: {
  slots: Slot[];
  onInfoPressed: (slot: Slot) => void;
  onEditPressed: (slot: Slot) => void;
}) => {
  return (
    <TableContainer sx={{ maxHeight: 512 }}>
      <Table stickyHeader aria-label="Slots">
        <TableHead>
          <TableRow>
            <TableCell align={"center"}>Action</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Redeemed Users</TableCell>
            <TableCell>Redemption</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {slots.map((slot) => (
            <TableRow key={slot.id}>
              <TableCell align={"center"}>
                <Tooltip title="Edit Slot">
                  <IconButton onClick={() => onEditPressed(slot)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"View Details"}>
                  <IconButton onClick={() => onInfoPressed(slot)}>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>{`SLOT ${slot.id}`}</TableCell>
              <TableCell>{slot.name}</TableCell>
              <TableCell>{slot.totalRedeemedUsers}</TableCell>
              <TableCell>
                {slot.redemptionAllowed ? (
                  <Typography variant={"body2"} color={"green"}>
                    ALLOWED
                  </Typography>
                ) : (
                  <Typography variant={"body2"} color={"error"}>
                    DISABLED
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

type SlotRedemptionUser = {
  id: string;
  userId: string;
  name: string;
  pName: string;
  redeemedAt: string;
};

const UsersListDialog = ({
  slot,
  onClose,
  onDelete,
  onReset,
  onResetComplete,
}: {
  slot: Slot;
  onClose: () => void;
  onDelete: () => void;
  onReset: () => void;
  onResetComplete: () => void;
}) => {
  const [users, setUsers] = useState<SlotRedemptionUser[]>([]);
  const [loading, setLoading] = useState(false);

  const [searchText, setSearchText] = useState<string>("");

  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(MyPaginationDefaultLimit);

  const paginationRef = useRef<MyPaginationRefType>();

  const firstTime = useRef<boolean>(true);

  const [resetting, setResetting] = useState(false);
  const [deletingUser, setDeletingUser] = useState<string | undefined>(undefined);

  const load = () => {
    setLoading(true);
    setUsers([]);

    makeBackendRequest("POST", "/admin/slotRedeemedUsers", {
      slotId: slot.id,
      search: searchText && searchText.trim() ? searchText.trim() : undefined,
      skip,
      limit,
    })
      .then(setUsers)
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    load();
  }, [skip, limit, searchText]);

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;

      load();
      return;
    }
  }, []);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    paginationRef.current?.resetPage();
    setSearchText(e.target.value);
  };

  const reset = () => {
    if (
      prompt(
        `Are you sure you want to reset this list? It will enable all the SLOT ${slot.id} users to redeem one more time. It will also reset their points to 0.\n\nType 'reset' to confirm.`
      ) !== "reset"
    ) {
      return;
    }

    const message = prompt(
      "Please enter the transaction message to be shown to the user for this reset."
    );

    if (!message || !message.trim()) {
      alert("Error: Please enter a valid message.");
      return;
    }

    if (
      prompt(
        `Are you sure you want to reset this list? This action cannot be undone.\n\nTxn Message:\n"${message}"\n\nType 'confirm' to confirm.`
      ) !== "confirm"
    ) {
      return;
    }

    setResetting(true);
    makeBackendRequest("DELETE", "/admin/resetSlot", {
      slotId: slot.id,
      message,
    })
      .then(() => {
        onClose();
        alert("Reset successful");
        onReset();
      })
      .catch(console.error)
      .finally(() => {
        setResetting(false);
        onResetComplete();
      });
  };

  const deleteRedeemedUser = (slotRedemptionId: string) => {
    if (
      !window.confirm(
        "Are you sure you want to delete this user? This will allow them to redeem again. Points must be additionally provided if needed."
      )
    ) {
      return;
    }

    setDeletingUser(slotRedemptionId);
    makeBackendRequest("DELETE", "/admin/clearSlotRedeemedUser", { slotRedemptionId })
      .then(() => {
        // NOTE: This doesn't handle the scenario when last redeemed user is cleared
        // It can be ignored for now.
        onClose();
        alert("User deleted successfully");
        onDelete();
      })
      .catch(console.error)
      .finally(() => {
        setDeletingUser(undefined);
      });
  };

  return (
    <Dialog maxWidth={"md"} fullWidth open={true} onClose={onClose}>
      <DialogTitle>{`Slot ${slot.id} - Redeemed Users`}</DialogTitle>
      <DialogContent>
        {slot.totalRedeemedUsers === 0 ? (
          <DialogContentText>No redemptions yet.</DialogContentText>
        ) : (
          <>
            <Button
              disabled={resetting}
              onClick={reset}
              variant={"contained"}
              color={"error"}
            >
              {resetting ? "Reset in Progress..." : "Reset List"}
            </Button>
            <Box mt={4} mb={1} sx={{ maxWidth: 384 }}>
              <FormGroup>
                <Input
                  defaultValue={searchText}
                  onChange={handleSearchTextChange}
                  placeholder={"Enter ID, RD Name, or Proprietor Name"}
                />
              </FormGroup>
            </Box>
            <TableContainer sx={{ maxHeight: 384 }}>
              <Table stickyHeader aria-label="Upload History">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>RD Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align={"center"}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={6} align={"center"}>
                        <CircularProgress size={24} />
                      </TableCell>
                    </TableRow>
                  )}
                  {users.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.userId}</TableCell>
                      <TableCell>{user.pName}</TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>
                        {new Date(user.redeemedAt).toLocaleDateString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </TableCell>
                      <TableCell align={"center"}>
                        {deletingUser === user.id ? (
                          <Box>
                            <CircularProgress color={"error"} size={24} />
                          </Box>
                        ) : (
                          <Button
                            onClick={() => deleteRedeemedUser(user.id)}
                            color={"error"}
                          >
                            Delete
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <MyPagination
              ref={paginationRef}
              onLimitChanged={(newLimit) => setLimit(newLimit)}
              onSkipChanged={(newSkip) => setSkip(newSkip)}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const Info = ({
  slot,
  onBackPressed,
  onReset,
  onResetComplete,
  onDelete,
}: {
  slot: Slot;
  onBackPressed: () => void;
  onDelete: () => void;
  onReset: () => void;
  onResetComplete: () => void;
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const Item = ({
    label,
    value,
    valueProps,
  }: {
    label: string;
    value: string;
    valueProps?: TypographyProps;
  }) => (
    <Box mt={2}>
      <Typography variant={"subtitle2"}>{label}</Typography>
      <Typography variant={"body2"} whiteSpace={"pre-line"} {...valueProps}>
        {value}
      </Typography>
    </Box>
  );

  return (
    <Box>
      {dialogOpen && (
        <UsersListDialog
          onDelete={onDelete}
          onReset={onReset}
          onResetComplete={onResetComplete}
          slot={slot}
          onClose={() => setDialogOpen(false)}
        />
      )}
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <IconButton
          onClick={() => {
            onBackPressed();
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant={"h6"} mr={2}>
          {`SLOT ${slot.id} - Info`}
        </Typography>
      </Box>
      <Box mt={2} mx={5}>
        <Button onClick={() => setDialogOpen(true)} variant={"contained"}>
          Redeemed Users List
        </Button>

        <Box
          mt={4}
          mb={2}
          maxWidth={512}
          marginLeft={"auto"}
          marginRight={"auto"}
          border={"1px solid #e0e0e0"}
          p={2}
          display={"flex"}
          flexDirection={"column"}
        >
          <Typography variant={"h6"}>General</Typography>
          <Item label={"Slot"} value={`SLOT ${slot.id}`} />
          <Item label={"Name"} value={slot.name} />
          <Item
            label={"Redemption"}
            value={slot.redemptionAllowed ? "Allowed" : "Disabled"}
          />
          <Typography mt={4} variant={"h6"}>
            Marketing
          </Typography>
          <Item label={"Banner"} value={""} />
          <SlotImage src={slot.cardImageUrl} />
          <Item label={"Title"} value={slot.cardTitle} />
          <Item label={"Description"} value={slot.cardDescription} />
        </Box>
      </Box>
    </Box>
  );
};

const SlotImage = ({ src }: { src: string }) => {
  const [bg, setBg] = useState<string | undefined>("#e0e0e0");

  return (
    <Box
      component={"img"}
      style={{
        maxWidth: 512,
        maxHeight: 512,
        backgroundColor: bg,
        minHeight: 512,
      }}
      src={src}
      onLoad={() => setBg(undefined)}
      alt={"Marketing Banner"}
    />
  );
};

const Edit = ({
  slot,
  onBackPressed,
  onSlotUpdated,
}: {
  slot: Slot;
  onBackPressed: () => void;
  onSlotUpdated: (updatedSlot: Slot) => void;
}) => {
  const [saving, setSaving] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string>(slot.cardImageUrl);

  const form = useRef<{
    cardImageUrl?: string;
    name?: string;
    cardTitle?: string;
    cardDescription?: string;
    redemptionAllowed?: boolean;
  }>({});

  const handleSave = () => {
    setSaving(true);

    makeBackendRequest("POST", "/admin/editSlot", {
      slotId: slot.id,
      ...form.current,
    })
      .then(() => {
        onSlotUpdated({ ...slot, ...form.current });
      })
      .catch(console.error)
      .finally(() => {
        setSaving(false);
      });
  };

  const handleSelectImageClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
    setPreviewImage(slot.cardImageUrl);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      if (file.type !== "image/png") {
        alert("Error: Only png files are supported");
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        alert("Error: File size too large. Max size is 2MB");
        return;
      }

      // Convert to base64
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        const imageB64 = reader.result as string;

        const tempImage = new Image();
        tempImage.onload = () => {
          if (tempImage.width !== 1024 || tempImage.height !== 1024) {
            alert("Error: Image dimensions must be 1024x1024");
            return;
          }

          form.current.cardImageUrl = imageB64;
          setPreviewImage(imageB64);
        };
        tempImage.src = imageB64;
      };

      reader.onerror = (error) => {
        alert(
          "Error: Unknown error occurred while processing image. Please try again. If this is repeated, please use a different image or web browser."
        );
      };
    }
  };

  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <IconButton
          onClick={() => {
            onBackPressed();
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant={"h6"} mr={2}>
          {`SLOT ${slot.id} - Edit`}
        </Typography>

        <Box display={"flex"} flexDirection={"column"} flex={1} alignItems={"flex-end"}>
          <Button
            disabled={saving}
            startIcon={<SaveIcon />}
            variant={"contained"}
            onClick={handleSave}
          >
            {saving ? "Saving..." : "Save"}
          </Button>
        </Box>
      </Box>
      <Box mt={4} mx={5}>
        <Box
          my={2}
          maxWidth={512}
          marginLeft={"auto"}
          marginRight={"auto"}
          border={"1px solid #e0e0e0"}
          p={2}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            textAlign: "center",
          }}
          alignItems={"center"}
        >
          <SlotImage src={previewImage} />
          <Box mt={2} width={"100%"}>
            <Button component="label">
              {"Change Banner"}
              <input
                onClick={handleSelectImageClick}
                onChange={handleFileChange}
                hidden
                type={"file"}
                accept={".png"}
              />
            </Button>
          </Box>

          <Box mt={2} width={"100%"}>
            <Alert severity="info" sx={{ textAlign: "left" }}>
              <AlertTitle>Marketing - Banner Specification</AlertTitle>
              <strong>Resolution</strong> - 1024x1024
              <br />
              <strong>File Type</strong> - PNG
              <br />
              <strong>Max Size</strong> - 2MB (Smaller size is preferred)
            </Alert>
          </Box>

          <Box mt={4} width={"100%"}>
            <Select fullWidth value={0} disabled>
              <option value={0}>{`SLOT ${slot.id}`}</option>
            </Select>
          </Box>

          <Box mt={4} width={"100%"}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(e, checked) => (form.current.redemptionAllowed = checked)}
                    defaultChecked={slot.redemptionAllowed}
                  />
                }
                label="Allow Redemptions"
              />
            </FormGroup>
          </Box>

          <Box mt={4} width={"100%"}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label={"Name"}
              defaultValue={slot.name}
              variant="outlined"
              fullWidth={true}
              onChange={(e) => (form.current.name = e.target.value)}
            />
          </Box>

          <Box mt={4} width={"100%"}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label={"Marketing - Title"}
              defaultValue={slot.cardTitle}
              variant="outlined"
              fullWidth={true}
              onChange={(e) => (form.current.cardTitle = e.target.value)}
            />
          </Box>

          <Box mt={4} width={"100%"}>
            <TextField
              InputLabelProps={{ shrink: true }}
              label={"Marketing - Description"}
              defaultValue={slot.cardDescription}
              variant="outlined"
              rows={8}
              multiline={true}
              fullWidth={true}
              onChange={(e) => (form.current.cardDescription = e.target.value)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SlotActionsCard;
