import {
  Button,
  CircularProgress,
  FormGroup,
  Grid,
  IconButton,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import React, { useEffect, useImperativeHandle, useRef } from "react";
import { getAccountTypeName } from "../utils/Misc";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import MyPagination, {
  MyPaginationDefaultLimit,
  MyPaginationRefType,
} from "./MyPagination";
import makeBackendRequest from "../utils/Backend";

const UserActionsCard = () => {
  const [mode, setMode] = React.useState<"search" | "info" | "edit">("search");
  const [skip, setSkip] = React.useState(0);
  const [limit, setLimit] = React.useState<number>(MyPaginationDefaultLimit);

  const [data, setData] = React.useState<UserData[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [searchText, setSearchText] = React.useState("");

  const [selectedUser, setSelectedUser] = React.useState<UserData | undefined>(undefined);

  // Edit
  const [saving, setSaving] = React.useState(false);

  const editSectionRef = React.createRef<{
    getMutations: () => { [key: string]: string };
    clearMutations: () => void;
  }>();

  // Clear edit mutations when the user changes
  React.useEffect(() => {
    editSectionRef.current?.clearMutations();
  }, [selectedUser]);

  const handleSave = () => {
    if (editSectionRef.current) {
      const mutations = editSectionRef.current.getMutations();

      const edits: { field: string; value: string }[] = [];
      for (const field in mutations) {
        edits.push({ field, value: mutations[field] });
      }

      if (edits.length === 0) {
        alert("Error: No changes to save. Modify a field to save.");
        return;
      }

      setSaving(true);

      makeBackendRequest("POST", "/admin/editUser", {
        id: selectedUser?.id,
        edits,
      })
        .then((res) => {
          alert("Saved!");
          if (selectedUser) {
            const newSelectedUser = { ...selectedUser };
            for (const edit of edits) {
              newSelectedUser.profile[edit.field] = edit.value;
            }
            setSelectedUser(newSelectedUser);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setSaving(false);
        });
    }
  };

  const paginationRef = React.useRef<MyPaginationRefType>();

  const hasFirstTimeLoaded = useRef(false);

  const load = () => {
    setLoading(true);
    setData([]);

    makeBackendRequest("POST", "/admin/users", {
      skip,
      limit,
      includeProfile: true,
      search: searchText && searchText.trim() ? searchText.trim() : undefined,
    })
      .then((res) => {
        setData(res);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
        hasFirstTimeLoaded.current = true;
      });
  };

  useEffect(() => {
    if (hasFirstTimeLoaded.current) {
      load();
    }
  }, [skip, limit, searchText]);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    paginationRef.current?.resetPage();
    setSearchText(e.target.value);
  };

  const firstTime = useRef(true);

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;

      load();
      return;
    }
  }, []);

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <Typography variant={"h5"} mb={2}>
          Users
        </Typography>
        {mode === "info" || mode === "edit" ? (
          <>
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <IconButton
                onClick={() => {
                  setMode("search");
                  setSelectedUser(undefined);
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant={"h6"} mr={2}>
                {mode === "info" ? "User Info" : "Edit User"}
              </Typography>
              {mode === "edit" && (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  flex={1}
                  alignItems={"flex-end"}
                >
                  <Button
                    onClick={handleSave}
                    disabled={saving}
                    startIcon={<SaveIcon />}
                    variant={"contained"}
                  >
                    {saving ? "Saving..." : "Save"}
                  </Button>
                </Box>
              )}
            </Box>
            <Box mt={2} />
            <Box maxHeight={"60vh"} overflow={"scroll"} paddingX={5}>
              {mode === "info" && selectedUser && <InfoSection user={selectedUser} />}
              {mode === "edit" && selectedUser && (
                <EditSectionUI ref={editSectionRef} user={selectedUser} />
              )}
            </Box>
          </>
        ) : undefined}

        {mode === "search" && (
          <>
            <Typography variant={"h6"} mr={2}>
              Search
            </Typography>
            <Box mb={1} sx={{ maxWidth: 384 }}>
              <FormGroup>
                <Input
                  defaultValue={searchText}
                  onChange={handleSearchTextChange}
                  placeholder={"Enter any ID, RD Name, or Proprietor Name"}
                />
              </FormGroup>
            </Box>
            <TableContainer sx={{ maxHeight: 512 }}>
              <Table stickyHeader aria-label="Users">
                <TableHead>
                  <TableRow>
                    <TableCell align={"center"}>Action</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>RD Name</TableCell>
                    <TableCell>Proprietor Name</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading && (
                    <TableRow>
                      <TableCell colSpan={6} align={"center"}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {data.map((user, idx) => {
                    return (
                      <TableRow key={user.id} hover role="checkbox" tabIndex={-1}>
                        <TableCell align={"center"}>
                          <Tooltip title="Edit User">
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setMode("edit");
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"View Details"}>
                            <IconButton
                              onClick={() => {
                                setSelectedUser(user);
                                setMode("info");
                              }}
                            >
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{getAccountTypeName(user.accountType)}</TableCell>
                        <TableCell
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {user.name}
                        </TableCell>
                        <TableCell
                          style={{
                            textTransform: "uppercase",
                          }}
                        >
                          {user.pName}
                        </TableCell>
                        <TableCell>
                          {user.isDisabled ? (
                            <Typography variant={"body2"} color={"error"}>
                              INACTIVE
                            </Typography>
                          ) : (
                            <Typography variant={"body2"} color={"green"}>
                              ACTIVE
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <MyPagination
              ref={paginationRef}
              defaultSkip={skip}
              defaultLimit={limit}
              onLimitChanged={(newLimit) => setLimit(newLimit)}
              onSkipChanged={(newSkip) => setSkip(newSkip)}
            />
          </>
        )}
      </Box>
    </Paper>
  );
};

// Imported and modified from mrgold-lapp-backend prisma bindings
type UserData = {
  id: string;
  points: number;
  accountType: number;
  isDisabled: boolean;
  name: string;
  pName: string;
  profile: {
    [key: string]: string;
    dob: string;
    mobile: string;
    whatsApp: string;
    email: string;
    residenceAddress: string;
    residencePinCode: string;
    residenceLandMark: string;
    wifeName: string;
    anniversaryDate: string;
    firstKidName: string;
    firstKidDob: string;
    secondKidName: string;
    secondKidDob: string;
    bankAccountName: string;
    bankAccountNumber: string;
    bankName: string;
    bankBranch: string;
    bankIFSC: string;
    addlAadhaar: string;
    addlPAN: string;
    addlGST: string;
    addlFSSAI: string;
    addlOtherDocs: string;
    addlRemarks: string;
    retName: string;
    retShopAddress: string;
    retShopPinCode: string;
    retShopLandMark: string;
    retDistRdCode: string;
    distZone: string;
    distRdType: string;
    distAsmName: string;
    distYearOfBusiness: string;
    distGodownSqft: string;
    distRdMarketArea: string;
    distTurnOverPerMonth: string;
    distInfraDetails: string;
    distMrGoldInvestment: string;
    distOtherBrandInvestment: string;
    distOtherBrandBusiness: string;
  };
};

type FieldProps = {
  name: string;
  value: string;
  edit?: {
    field: string; // Field name of the user object
  };
};

const convertUserToFields = (user: UserData): FieldProps[] => {
  const fields = [
    { name: "ID", value: user.id },
    { name: "Account Type", value: getAccountTypeName(user.accountType) },
    { name: "Account Status", value: user.isDisabled ? "INACTIVE" : "ACTIVE" },
    { name: "Points", value: user.points.toString() },
    { name: "RD Name", value: user.name },
    { name: "Proprietor Name", value: user.pName },
    {
      name: "Date of Birth",
      value: user.profile.dob,
      edit: {
        field: "dob",
      },
    },
    {
      name: "Mobile Number",
      value: user.profile.mobile,
      edit: {
        field: "mobile",
      },
    },
    {
      name: "WhatsApp Number",
      value: user.profile.whatsApp,
      edit: {
        field: "whatsApp",
      },
    },
    {
      name: "Email",
      value: user.profile.email,
      edit: {
        field: "email",
      },
    },
    {
      name: "Residence Address",
      value: user.profile.residenceAddress,
      edit: {
        field: "residenceAddress",
      },
    },
    {
      name: "Residence Pin Code",
      value: user.profile.residencePinCode,
      edit: {
        field: "residencePinCode",
      },
    },
    {
      name: "Residence Land Mark",
      value: user.profile.residenceLandMark,
      edit: {
        field: "residenceLandMark",
      },
    },
    {
      name: "Wife's Name",
      value: user.profile.wifeName,
      edit: {
        field: "wifeName",
      },
    },
    {
      name: "Anniversary Date",
      value: user.profile.anniversaryDate,
      edit: {
        field: "anniversaryDate",
      },
    },
    {
      name: "First Kid's Name",
      value: user.profile.firstKidName,
      edit: {
        field: "firstKidName",
      },
    },
    {
      name: "First Kid's Date of Birth",
      value: user.profile.firstKidDob,
      edit: {
        field: "firstKidDob",
      },
    },
    {
      name: "Second Kid's Name",
      value: user.profile.secondKidName,
      edit: {
        field: "secondKidName",
      },
    },
    {
      name: "Second Kid's Date of Birth",
      value: user.profile.secondKidDob,
      edit: {
        field: "secondKidDob",
      },
    },
    {
      name: "Bank Account Name",
      value: user.profile.bankAccountName,
      edit: {
        field: "bankAccountName",
      },
    },
    {
      name: "Bank Account Number",
      value: user.profile.bankAccountNumber,
      edit: {
        field: "bankAccountNumber",
      },
    },
    {
      name: "Bank Name",
      value: user.profile.bankName,
      edit: {
        field: "bankName",
      },
    },
    {
      name: "Bank Branch",
      value: user.profile.bankBranch,
      edit: {
        field: "bankBranch",
      },
    },
    {
      name: "Bank IFSC",
      value: user.profile.bankIFSC,
      edit: {
        field: "bankIFSC",
      },
    },
    {
      name: "Addl. Aadhaar",
      value: user.profile.addlAadhaar,
      edit: {
        field: "addlAadhaar",
      },
    },
    {
      name: "Addl. PAN",
      value: user.profile.addlPAN,
      edit: {
        field: "addlPAN",
      },
    },
    {
      name: "Addl. GST",
      value: user.profile.addlGST,
      edit: {
        field: "addlGST",
      },
    },
    {
      name: "Addl. FSSAI",
      value: user.profile.addlFSSAI,
      edit: {
        field: "addlFSSAI",
      },
    },
    {
      name: "Addl. Other Docs",
      value: user.profile.addlOtherDocs,
      edit: {
        field: "addlOtherDocs",
      },
    },
    {
      name: "Addl. Remarks",
      value: user.profile.addlRemarks,
      edit: {
        field: "addlRemarks",
      },
    },
    {
      name: "(RET) Name",
      value: user.profile.retName,
      edit: {
        field: "retName",
      },
    },
    {
      name: "(RET) Shop Address",
      value: user.profile.retShopAddress,
      edit: {
        field: "retShopAddress",
      },
    },
    {
      name: "(RET) Shop Pin Code",
      value: user.profile.retShopPinCode,
      edit: {
        field: "retShopPinCode",
      },
    },
    {
      name: "(RET) Shop Land Mark",
      value: user.profile.retShopLandMark,
      edit: {
        field: "retShopLandMark",
      },
    },
    {
      name: "(RET) Distributor RD Code",
      value: user.profile.retDistRdCode,
      edit: {
        field: "retDistRdCode",
      },
    },
    {
      name: "(DIST) Zone",
      value: user.profile.distZone,
      edit: {
        field: "distZone",
      },
    },
    {
      name: "(DIST) Road Type",
      value: user.profile.distRdType,
      edit: {
        field: "distRdType",
      },
    },
    {
      name: "(DIST) ASM Name",
      value: user.profile.distAsmName,
      edit: {
        field: "distAsmName",
      },
    },
    {
      name: "(DIST) Year of Business",
      value: user.profile.distYearOfBusiness,
      edit: {
        field: "distYearOfBusiness",
      },
    },
    {
      name: "(DIST) Godown Sqft",
      value: user.profile.distGodownSqft,
      edit: {
        field: "distGodownSqft",
      },
    },
    {
      name: "(DIST) Road Market Area",
      value: user.profile.distRdMarketArea,
      edit: {
        field: "distRdMarketArea",
      },
    },
    {
      name: "(DIST) Turnover per Month",
      value: user.profile.distTurnOverPerMonth,
      edit: {
        field: "distTurnOverPerMonth",
      },
    },
    {
      name: "(DIST) Infrastructure Details",
      value: user.profile.distInfraDetails,
      edit: {
        field: "distInfraDetails",
      },
    },
    {
      name: "(DIST) Mr Gold Investment",
      value: user.profile.distMrGoldInvestment,
      edit: {
        field: "distMrGoldInvestment",
      },
    },
    {
      name: "(DIST) Other Brand Investment",
      value: user.profile.distOtherBrandInvestment,
      edit: {
        field: "distOtherBrandInvestment",
      },
    },
    {
      name: "(DIST) Other Brand Business",
      value: user.profile.distOtherBrandBusiness,
      edit: {
        field: "distOtherBrandBusiness",
      },
    },
  ];
  return fields;
};

// Info Section
const InfoSection = ({ user }: { user: UserData }) => {
  const data = convertUserToFields(user);

  const [processing, setProcessing] = React.useState(false);

  const resetUserPassword = async () => {
    setProcessing(true);

    makeBackendRequest("POST", "/admin/resetUserPassword", {
      id: user.id,
    })
      .then((res) => {
        alert("Password reset successfully");
      })
      .catch((err) => {})
      .finally(() => {
        setProcessing(false);
      });
  };

  const updateActivationStatus = async (status: boolean) => {
    setProcessing(true);

    makeBackendRequest("POST", "/admin/setUserStatus", {
      id: user.id,
      active: status,
    })
      .then((res) => {
        if (status) {
          alert("User activated successfully");
        } else {
          alert("User deactivated successfully");
        }

        // Not the right approach, but works for now
        user.isDisabled = !status;
      })
      .catch((err) => {})
      .finally(() => {
        setProcessing(false);
      });
  };

  const activateAccount = async () => updateActivationStatus(true);

  const deactivateAccount = async () => updateActivationStatus(false);

  return (
    <Box>
      {processing && (
        <Box>
          <CircularProgress />
        </Box>
      )}

      {!processing && (
        <>
          <Button
            onClick={resetUserPassword}
            sx={{
              marginRight: 2,
            }}
            variant={"outlined"}
            color={"warning"}
          >
            Reset User Password
          </Button>

          {user.isDisabled ? (
            <Button onClick={activateAccount} variant={"contained"} color={"primary"}>
              Activate Account
            </Button>
          ) : (
            <Button onClick={deactivateAccount} variant={"contained"} color={"error"}>
              Deactivate Account
            </Button>
          )}
        </>
      )}

      <Grid mt={0} mb={2} container spacing={4}>
        {data.map((row, idx) => (
          <Grid key={idx} item sm={4} md={3}>
            <Box>
              <Typography variant={"body2"} fontWeight={"500"}>
                {row.name}
              </Typography>
              <Typography variant={"body2"}>{row.value ? row.value : "-"}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

// Edit Section
const EditSectionUI = React.forwardRef(({ user }: { user: UserData }, ref) => {
  const data = convertUserToFields(user);

  const mutatedFields = React.useRef<Map<string, string>>(new Map<string, string>());

  useImperativeHandle(ref, () => ({
    getMutations: () => {
      const mutations: any = {};
      mutatedFields.current.forEach((value, key) => {
        mutations[key] = value;
      });
      // Return all the mutations regardless of whether they are dirty or not
      return mutations;
    },
    clearMutations: () => {
      mutatedFields.current.clear();
    },
  }));

  return (
    <>
      <Box my={2}>
        <Grid container spacing={4}>
          {data.map((row, idx) => (
            <Grid key={idx} item sm={4} md={3}>
              <Box>
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  label={row.name}
                  defaultValue={row.value}
                  variant="outlined"
                  disabled={!row.edit}
                  onChange={(e) => {
                    if (row.edit) {
                      mutatedFields.current.set(row.edit.field, e.target.value);
                    }
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
});

export default UserActionsCard;
